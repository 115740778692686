<template>
  <AButton
    type="button"
    variant="link"
    class="icon-text-button d-flex align-items-center"
    :class="[{ active }]"
    @click="select"
  >
    <AImage
      v-if="cData.img"
      :src="`${$base_media_url}/${cData.img}`"
      :alt="cData.name || cData.img"
    />
    <slot />
  </AButton>
</template>

<script>
export default {
  name: "icon-text-option",
  props: {
    value: [String, Number],
    iconSrc: String,
    iconAlt: String,
    active: [Boolean, Object, Number, String],
    data: Object
  },
  watch: {
    active(a) {
      if (a === this.cData.id) this.select();
    }
  },
  computed: {
    cData() {
      return this.data?.country;
    },
    cActive() {
      return this.active;
    }
  },
  mounted() {
    if (this.cActive === this.cData.id) this.select();
  },
  methods: {
    select() {
      this.$parent.$parent.$emit("a-dropdown-selected", this.cData);
      this.$parent.$parent.$emit("update:a-dropdown-selected", this.cData);
      this.$parent.$parent.$emit(
        "update:a-dropdown-selected-id",
        this.cData.id
      );
    }
  }
};
</script>
